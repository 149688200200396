:root {
  --dark: #010101;
  --light: #edfded;
  --orange: #df5227;
  --blue:#2188FF;
}

@font-face {
  font-family: "InterRegular";
  src: url("../fonts/Inter/static/Inter-Regular.ttf");
}
@font-face {
  font-family: "InterMedium";
  src: url("../fonts/Inter/static/Inter-Regular.ttf");
  src: url("../fonts/Inter/static/Inter-Medium.ttf");
  src: url("../fonts/Inter/static/Inter-Bold.ttf");
}
@font-face {
  font-family: "InterBold";
  src: url("../fonts/Inter/static/Inter-Bold.ttf");
}
@-webkit-keyframes slideDown {
  0% {
    visibility: hidden;
    height: 0;
  }
  95% {
    visibility: visible;
    height: 25vh;
  }
}

@keyframes slideDown {
  0% {
    visibility: hidden;
    height: 0;
  }
  95% {
    visibility: visible;
    height: 25vh;
  }
}
@keyframes hoveranim {
  0% {
    opacity: 0.3;
  }
  95% {
    opacity: 1;
  }
}
@-webkit-keyframes slideInUp {
  0% {
    opacity: 0;
    transform: translateY(20%);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}

@keyframes slideInUp {
  0% {
    opacity: 0;
    transform: translateY(20%);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}

@-webkit-keyframes backgroundAnim {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}
@keyframes backgroundAnim {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}
body {
  background-color: var(--dark);
  font-family: "InterMedium";
  color: var(--light);
  overflow-x: hidden;
  margin: 0 auto;
}
body::-webkit-scrollbar{
  width: 5px;
  background-color: transparent;
  border-radius: 5px;
}
body::-webkit-scrollbar-thumb{
  background-color: #f76336;
  border-radius: 5px;
}

/* Menu */
.navbar {
  display: flex;
  background-color: transparent;
  justify-content: space-between;
  padding: 0.6em 0.8em;
  width: 80vw;
  margin-right: auto;
  margin-top: 2em;
  margin-left: auto;
  animation-name: slideInUp;
  animation-duration: 1s;
  animation-delay: 0.2s;
}

.navbar-items {
  display: flex;
  justify-content: space-around;
  align-content: flex-end;
}

.navbar-brand {
  vertical-align: middle;
}
.Nav-father {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.navbar-item {
  text-decoration: none;
  color: var(--light);
  letter-spacing: -0.1px;
  font-weight: 600;
  padding: 0.8em 1.2em;
  margin: 0 1em;
  height: fit-content;
}
.navbar-itemR {
  text-decoration: none;
  color: var(--light);
  letter-spacing: -0.1px;
  font-weight: 600;
  padding: 0.8em 1.2em;
  margin: 0 1em;
  height: fit-content;
}
.primary-item {
  color: var(--light);
  background-color: var(--orange);
  border-radius: 50px;
  margin-top: 0;
  margin-bottom: 0;
  width: 100px;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.primary-item:hover {
  background-color: #df5227;
  animation-duration: 5s;
}
.navbar-item:hover {
  color: var(--orange);
  animation-delay: 2s;
}
.navbar-itemR:hover {
  color: #edfded;
}

.menu {
  display: none;
  flex-direction: column;
  justify-content: center;
}

.menu-btn {
  width: 30px;
  height: 25px;
  position: relative;
  margin-left: auto;
  border: none;
  cursor: pointer;
  margin-right: auto;
  margin-left: auto;
  transition: ease 0.3s, top ease 0.3s 0.3s, transform ease 0.3s;
  outline: none;
  z-index: 5;
}

.menu-btn span {
  display: flex;
  position: absolute;
  height: 2px;
  width: 100%;
  background: var(--light);
  border-radius: 5px;
  transition: ease 0.3s, top ease 0.3s 0.3s, transform ease 0.3s;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
}

.menu-btn.open {
  transform: rotate(180deg);
}

.menu-btn span:nth-child(1) {
  top: 0px;
}

.menu-btn span:nth-child(2),
.menu-btn span:nth-child(3) {
  top: 10px;
}

.menu-btn span:nth-child(4) {
  top: 20px;
}

.menu-btn.open span:nth-child(1) {
  top: 10px;
  width: 0%;
  left: 50%;
}

.menu-btn.open span:nth-child(2) {
  transform: rotate(45deg);
}

.menu-btn.open span:nth-child(3) {
  transform: rotate(-45deg);
}

.menu-btn.open span:nth-child(4) {
  top: 10px;
  width: 0%;
  left: 50%;
}

.slideDown {
  animation-name: slideDown;
  animation-duration: 0.5s;
}

.menu-items {
  display: flex;
  position: absolute;
  left: 0%;
  flex-direction: column;
  justify-content: center;
  width: 100vw;
  height: 25vh;
  top: 15vh;
  background-color: #121213;
}

.menu-item {
  z-index: 10;
  text-decoration: none;
  color: var(--light);
  letter-spacing: -0.1px;
  font-weight: 600;
  width: 60vw;
  text-align: center;
  padding: 0.8em 2.4em;
  margin: 0 auto;
}

.hide {
  display: none;
}

.hideAnim {
  visibility: hidden;
}

.spaceY {
  padding: 2em 0;
  margin-top: 2em;
}

.logo {
  max-width: 80%;
  max-height: 15vh;
  margin-right: auto;
  margin-left: auto;
  cursor: pointer;
}

.Row {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 0 auto;
  justify-content: center;
}

/* MENU END */

/* Home */
.hero {
  justify-content: center;
  height: 60vh;
  margin: 0 auto;
  width: 95vw;
  display: flex;
  flex-direction: column;
}
.hero h1 {
  color: var(--light);
  text-align: center;
  font-size: 3.5em;
  padding: 0.6em;
  width: 60%;
  font-weight: 600;
  margin: 0 auto;
  animation-name: slideInUp;
  animation-duration: 1s;
  animation-delay: 0.2s;
}
.hero p {
  color: var(--light);
  margin: 0.2em auto;
  font-size: 1em;
  text-transform: uppercase;
  font-weight: 800;
  text-align: center;
  opacity: 0.4;
  animation-name: slideInUp;
  animation-duration: 1s;
  animation-delay: 0.4s;
}
.hero h1 span {
  /* background: url("../img/background.png") repeat-y;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-animation: backgroundAnim 40s linear infinite;
  animation: backgroundAnim 40s linear infinite;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden; */
color: var(--orange);
}

/* buttons */
.button-group {
  padding: 1em 0;
  display: flex;
  justify-content: center;
  animation-name: slideInUp;
  animation-duration: 1s;
  animation-delay: 0.4s;
}

.button-group button {
  padding: 1em 2.1em;
  outline: none;
  border: none;
  border-radius: 50px;
  margin: 1em;
  font-size: 1em;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  letter-spacing: -0.1px;
  color: var(--light);
}

.button-group .primary {
  background-color: var(--orange);
  cursor: pointer;
}

.button-group .secondary {
  background-color: #2a2d32;
  cursor: pointer;
}

.button-group-sub {
  padding: 1em 0;
  display: flex;
  justify-content: left;
  animation-name: slideInUp;
  animation-duration: 1s;
  animation-delay: 0.4s;
}

.button-group-sub button {
  padding: 1em 2.1em;
  outline: none;
  border: none;
  border-radius: 2px;
  font-weight: 600;
  letter-spacing: -0.1px;
  color: var(--light);
}

.button-group-sub .primary {
  background-color: var(--orange);
  cursor: pointer;
}

/* button end */

.details {
  width: 80vw;
  margin: 0 auto;
  height: 78vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.details-sub {
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  font-weight: 300;
  justify-content: space-around;
}
.details img {
  max-width: 35%;
  float: right;
}
.details h1 {
  display: flex;
  flex-direction: column;
  width: 62%;
  color: var(--orange);
  opacity: 0.9;
  font-size: 0.8em;
  font-family: InterMedium;
}
.details-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 0.5em;
  width: 50%;
  font-family: InterRegular;
  font-weight: 400 !important;
  font-size: 1.2em;
  padding: 0 .8em;
}
.details-text h1{
  margin-bottom:0;

  font-family: InterMedium;
  font-size: 1.2em;
}

.content-card {
  width: 200px;
  height: 317px;
  border-radius: 20px;
  background: #f6f9ff;
  box-shadow: 8px 8px 24px rgba(9, 14, 20, 0.4);
}

.services {
  width: 90vw;
  padding: 3em 2em 1em 2em;
  display: flex;
  flex-direction: column;
  align-content: center;
  margin: 0 auto;
  margin-bottom: 2em;
  justify-content: center;
  transition-delay: 0.5s;
}

.serviceRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 1em;
  margin-bottom: 1em;
  width: 100%;
}

.serviceCard {
  width: 33%;
  display: flex;
  flex-direction: column;
  padding: 0 1em;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1em;
  justify-content: center;
}
.coreCard {
  width: 33%;
  display: flex;
  flex-direction: column;
  padding: 0 1em;
  margin-left: auto;
  margin-right: 1em;
  margin-bottom: 1em;
  justify-content: center;
  border-radius: 12px;
  background: #2a2d32;
  box-shadow: 6px 6px 18px rgba(0, 0, 0, 0.16);
}
.iconWrap {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.goService {
  background-color: var(--blue);
  padding: 1em 2em;
  border-radius: 5px;
  outline: none;
  border: 0;
  font-family: "Inter", sans-serif;
  font-size: 600;
  margin: 1em auto;
  cursor: pointer;
  color: var(--light);
  width: fit-content;
  animation-name: slideInUp;
  animation-duration: 1s;
  animation-delay: 0.4s;
}

.serviceIcon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 10vh;
  width: 10vh;
  background-color: #fff;
  text-align: center;
  align-content: center;
  padding-bottom: 0.2em;
  align-items: center;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
}

.serviceIcon img {
  vertical-align: middle;
  max-width: 100%;
  max-height: 5vh;
  filter: brightness(0);

}

.serviceTitle {
  color: var(--light);
  text-align: center;
  font-weight: 700;
  font-size: 1.7em;
  letter-spacing: -0.1px;
  padding: 0;
  padding-top: 0;
  margin-top: 0.7em;
  margin-bottom: 0.5em;
}

.serviceText {
  color: var(--grey);
  font-size: 1.1em;
  margin-top: 0;
  padding-top: 0;
  font-weight: 400;
  letter-spacing: -0.1px;
  text-align: center;
  font-family: InterRegular;
}
.coreCard:nth-child(1) {
  animation-name: slideInUp;
  animation-duration: 1s;
  animation-delay: 0.1s;
}

.coreCard:nth-child(2) {
  animation-name: slideInUp;
  animation-duration: 1s;
  animation-delay: 0.3s;
}

.coreCard:nth-child(3) {
  animation-name: slideInUp;
  animation-duration: 1s;
  animation-delay: 0.5s;
}
.serviceCard:nth-child(1) {
  animation-name: slideInUp;
  animation-duration: 1s;
  animation-delay: 0.1s;
}

.serviceCard:nth-child(2) {
  animation-name: slideInUp;
  animation-duration: 1s;
  animation-delay: 0.3s;
}

.serviceCard:nth-child(3) {
  animation-name: slideInUp;
  animation-duration: 1s;
  animation-delay: 0.5s;
}
.divider {
  width: 80vw;
  height: 0.5px;
  margin: 0 auto;
  background-color: #2a2d32;
}

/* Generalcontent */
.generalContent {
  width: 95vw;
  display: flex;
  margin: 1em auto;
  flex-direction: column;
  justify-content: center;
  transition-delay: 0.5s;
}
.generalContentRow {
  display: flex;
  flex-direction: row;
  width: 90vw;
  margin: 1.5em auto;
  justify-content: center;
}
.generalContentRow:nth-child(2) {
  transition-delay: 0.2s;
}

.generalContentInfo {
  width: 40%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 0 2em;
  margin: 0 2em 2em 0;
  animation-name: slideInRight;
  animation-duration: 1s;
  animation-delay: 0.1s;
}

.generalContentInfoRev {
  width: 40%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0 2em;
  margin: 2em 0 0 2em;
  animation-name: slideInLeft;
  animation-duration: 1s;
  animation-delay: 0.5s;
}

.generalContentInfoWrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.generalContentTag {
  text-transform: uppercase;
  margin-bottom: .3em;
  color: var(--orange);
  font-size: 1em;
  font-weight: 600;
}
.generalContentTagSingle {
  text-transform: uppercase;
  margin-bottom: 1.3em;
  color: var(--orange);
  font-size: 1em;
  font-weight: 600;
}
.generalContentTitle {
  font-size: 2em;
  margin: 0.3em 0;
  color: var(--light);
  font-weight: 700;
}

.generalContentText {
  color: #858585;
  font-size: 1.3em;
  margin: 0.1em 0;
  font-family: InterRegular;
}

.generalContentText span{
  color: var(--light);
}

.generalContentImg {
  min-height: 40vh;
  max-width: 40%;
  margin: 1em 2em;
  animation-name: slideInUpSmall;
  animation-duration: 1s;
  animation-delay: 0.5s;
}

/* home end */

/* Footer */
.footer-main {
  background-color: #0c0d0e;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100vw;
  margin-top: 2em;
  vertical-align: middle;
  font-family: InterRegular;
  padding-bottom: 0.8em;
}
.footer-sub {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.footer-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 1em;
}
.quickLinks {
  display: flex;
  flex-direction: column;
  margin-left: 1em;
  justify-content: center;
  font-weight: 100;
}
.quickLinks a {
  color: #edfded;
  text-decoration: none;
}
.quickLinks a:hover {
  color: var(--orange);
  animation-duration: 3s;
}
.footer-logo {
  width: 60vw;
  justify-content: space-between;
  padding: 0.6em 0.8em;
  margin-right: auto;
}
.footer-content {
  display: flex;
  flex-direction: column;
  margin-left: 3em;
  justify-content: center;
}
.Karri {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-right: 3em;
}
.footer-social {
  display: flex;
  flex-direction: row;
  margin-right: 2em;
}
.social {
  margin: 0 0.8em;
  justify-content: center;
}
.social svg {
  width: 73%;
}
.footer-copy {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

/* Footer End */

/* Contact */

.contact-main {
  display: flex;
  flex-direction: column;
  height: 100vh;
  margin: 0 1.8em;
  justify-content: center;
}
.contact-infomain {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.contact-row {
  display: flex;
}
.contact-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.contact-infosub {
  display: flex;
  flex-direction: column;
}
.contact-infosub h1 {
  color: var(--light);
  font-size: 1.8em;
  font-weight: 800;
  width: 100%;
  opacity: 0.7;
  margin: 0.75em 1em;
}

.contact-details {
  display: flex;
  flex-direction: column;
  margin: 0;
}
.contact-details h1 {
  color: var(--light);
  margin: 0;
  opacity: 0.5;
}
.contact-details h3 {
  color: var(--light);
  margin: 0;
  opacity: 0.5;
}
.contact-details p {
  width: 85%;
  color: var(--light);
  font-family: InterMedium;
  text-transform: uppercase;
}
.contact-details .ambassador {
  width: 80%;
  color: var(--light);
  font-family: InterMedium;
  text-transform: uppercase;
  margin: 1em 0;
  opacity: 0.5;
}
.contact-detail {
  margin: 0;
}
.contact-infosub p {
  color: var(--light);
  font-size: 1em;
  font-weight: 300;
}

.CDM {
  margin-bottom: 1em;
}

.contactPageCol {
  display: flex;
  width: 85%;
  flex-direction: column;
  justify-content: center;
  animation-name: slideInUp;
  animation-duration: 1s;
  animation-delay: 0.2s;
}

.contactPageCol h2 {
  font-size: 3.6em;
  font-weight: 800;
  color: var(--light);
  padding: 0;
  margin: 0.5em 0;
}
.getMessage {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.getMessageWarp {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.getMessageWarp label {
  color: var(--grey);
  font-size: 0.8em;
  font-weight: 300;
  font-family: InterRegular;
  margin-top: 0.5em;
}

.getMessageWarp input {
  background-color: #2a2d32;
  outline: none;
  border: 0;
  padding: 1em;
  max-width: 90%;
  border-radius: 5px;
  margin: 1em 0;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 0.8em;
  color: var(--light);
}

.getMessageWarp textarea {
  background-color: #2a2d32;
  outline: none;
  border: 0;
  padding: 1em;
  max-width: 90%;
  border-radius: 5px;
  margin: 1em 0;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 0.8em;
  color: #edfded;
}

.getMessage button {
  background-color: #df5227;
  outline: none;
  border: 0;
  padding: 1em 1.5em;
  border-radius: 5px;
  margin: 1em 0;
  color: var(--light);
  max-width: 55%;
  cursor: pointer;
  font-family: "Inter", sans-serif;
  font-weight: 600;
}

/* festival */

.imgcard {
  background-color: #18191b;
  box-shadow: 8px 8px 14px rgba(9, 13, 17, 0.4);
  border-radius: 12px;
}
.imgcard:hover {
  background-color: #18191b;
  box-shadow: 8px 8px 34px rgba(9, 14, 20, 0.174);
  border-radius: 12px;
  z-index: 125;
}

.announceCard {
  display: flex;
  flex-direction: column;
  padding: 1em;
  margin-left: auto;
  margin-bottom: 1em;
  justify-content: center;
  border-radius: 12px;
  background: #2a2d32;
  box-shadow: 6px 6px 18px rgba(0, 0, 0, 0.16);
}

.events {
  width: 100vw;
  padding: 3em 2em 1em 2em;
  display: flex;
  margin: 0 auto;
  margin-bottom: 2em;
  flex-direction: row;
  justify-content: center;
  transition-delay: 0.5s;
}

.eventCard {
  background-color: #2a2d32;
  height: 70vh;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  justify-content: center;
  width: 25%;
  margin: 0 1em;
}

.eventCard:nth-child(1) {
  animation-name: slideInLeft;
  animation-duration: 1s;
  animation-delay: 0.5s;
}

.eventCard:nth-child(2) {
  animation-name: slideInUpSmall;
  animation-duration: 1s;
  animation-delay: 0.5s;
}

.eventCard:nth-child(3) {
  animation-name: slideInRight;
  animation-duration: 1s;
  animation-delay: 0.5s;
}
.EventInfo {
  padding: 0 1.5em;
  margin: 0 auto;
  font-weight: 400;
  color: var(--grey);
  font-size: 1.2em;
}

.smallDivider {
  width: 85%;
  height: 0.5px;
  margin: 1em auto;
  margin-top: 2em;
  background-color: var(--grey);
  opacity: 0.5;
}

/* about page  */
.about-hero {
  justify-content: center;
  display: flex;
  flex-direction: column;
}
.about-hero h1 {
  color: var(--light);
  text-align: center;
  font-size: 3.5em;
  padding: 0.6em;
  width: 60%;
  font-weight: 600;
  margin: 0 auto;
  animation-name: slideInUp;
  animation-duration: 1s;
  animation-delay: 0.2s;
}
.about-hero p {
  color: var(--light);
  margin: 0.2em auto;
  font-size: 1em;
  text-transform: uppercase;
  font-weight: 800;
  text-align: center;
  opacity: 0.4;
  animation-name: slideInUp;
  animation-duration: 1s;
  animation-delay: 0.4s;
}
.about-hero h3 {
  color: var(--light);
  margin: 0.2em auto;
  font-size: 1em;
  text-transform: uppercase;
  font-weight: 800;
  text-align: center;
  animation-name: slideInUp;
  animation-duration: 1s;
  animation-delay: 0.4s;
}
.card {
  min-width: 250px;
  overflow: hidden;
  position: relative;
  margin: 0;
  display: inline-block;
}

.card img {
  width: 100%;
  min-height: 100%;
  transition: all 0.9s;
}
/* .card:hover img{
 transform:scale(1.2,1.2)
} */
.data {
  position: absolute;
  bottom: 5%;
  background: #212121;
  text-align: center;
  width: 90%;
  height: 80px;
  overflow: hidden;
  left: 0;
  right: 0;
  margin: 0 auto;
  padding: 10px 5%;
  box-sizing: border-box;
  opacity: 0.8;
  transition: all 0.4s;
}
.data-social{
  margin-top: 12em;
}

.card:hover .data {
  width: 100%;
  height: 100%;
  bottom: 0;
  opacity: 0.9;
  padding: 90px 10%;
}
.team {
  width: 75vw;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: left;
  float: left;
}
.team-sub {
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  font-weight: 300;
  justify-content: center;
}
.team img {
  max-width: 35%;
  float: right;
}
.team h1 {
  display: flex;
  flex-direction: column;
  width: 62%;
  color: var(--orange);
  opacity: 0.9;
  font-size: 0.8em;
  font-family: InterMedium;
}
.team-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 1em;
  width: 70%;
  font-family: InterRegular;
  font-weight: 400 !important;
  font-size: 1.2em;
  padding: 0 1em;
}
.teamMembers{
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0 auto;
}
.teamMember {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 45%;
  margin: 1em 0;
}


.data h2 {
  margin: 0 0 5px 0;
  transition: color 0.4s;
}
.data b{
  font-size: 1em;
  font-family: InterRegular;
  font-weight: 300;
}

.data p {
  opacity: 0;
  text-align: left;
  transition: all 0.2s;
}

.card:hover .data p,
.card:hover .data a {
  opacity: 1;
}

.data a {
  color: #333;
  text-decoration: none;
  padding: 20px;
  opacity: 0;
}
.data svg {
  fill: #edfded;
}
.data svg :hover {
  fill: #df5227;
  animation-name: hoveranim;
  transition: 0.3s;
}
.data a:hover,
.card:hover h2 {
  color: #fff;
}

@media (max-width: 799px) {
  .sub-hero {
    padding-left: 1em;
    padding-right: 1em;
  }

  .subheroText {
    font-size: 1.8em;
  }

  .subheroTag {
    font-size: 0.8em;
    width: 75%;
  }
  .card {
    width: 100%;
    margin-right: 0;
    justify-content: center;
  }
  .button-group {
    padding: 0.5em 0;
    display: flex;
    flex-direction: column;
    width: 75%;
    margin: 0 auto;
  }
  .details {
    width: 80vw;
    margin: 0 auto;
    height: 60vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .sub-hero {
    width: 100vw;
    padding: 2em 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .about-hero h1 {
    font-size: 2em;
    padding: 0 0.6em;
    width: 80%;
  }
  .team-text {
    margin: 1em 0 0 1em;
    width: 80vw;
    font-family: InterRegular;
    font-weight: 400 !important;
    font-size: 1.2em;
    padding: 0 1em;
  }
  .sub-hero-img {
    max-width: 80%;
    margin: 1em auto;
    filter: grayscale(70%);
    animation-name: slideInUpLarge;
    animation-duration: 1s;
    padding: 2em 0;
  }

  .subheroText {
    color: var(--light);
    font-size: 2.7em;
    letter-spacing: -0.3px;
    font-weight: 800;
    margin-bottom: 0.5em;
    text-align: center;
  }

  .subheroTag {
    text-align: center;
    color: var(--grey);
    font-size: 1.2em;
    letter-spacing: -0.1px;
    font-weight: 400;
    width: 45%;
    margin: 0 auto;
  }

  .getMessage {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .getMessageWarp {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .getMessageWarp input {
    max-width: 100%;
  }

  .getMessageWarp textarea {
    max-width: 100%;
  }

  .getMessage button {
    max-width: 75%;
    margin-left: auto;
    margin-right: auto;
  }  
  .coreCard {
    width: 100%;
    max-width: 70vw;
    display: flex;
    flex-direction: column;
    padding: 0 1em;
    margin: 0 auto;
    margin-bottom: 1em;
  }
  .services{
    max-width: 80vw;
    margin: 0 auto;
  }
  .serviceRow {
    flex-direction: column;
    margin: 0 auto;
    padding-right: 0;
    padding-left: 0;
  }

  .serviceCard {
    width: 80%;
    margin-bottom: 1.25em;
  }

  .serviceTitle {
    font-size: 1.4em;
  }

  .serviceText {
    font-size: 0.8em;
  }
  .generalContentRow {
    flex-direction: column;
  }

  .generalContentInfo {
    width: 85%;
    margin: 1em auto;
  }

  .generalContentInfoRev {
    width: 85%;
    margin: 1em auto;
  }

  .generalContentTag {
    font-size: 1em;
  }

  .generalContentTitle {
    font-size: 1.8em;
  }

  .generalContentText {
    font-size: 1em;
  }

  .generalContentImg {
    min-height: 0;
    max-width: 85%;
    margin: 1em auto;
  }  
  .teamMembers{
    width: 80vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
  }
  .teamMember {
    flex-direction: column;
    width: 100%;
    margin: 1em auto;
  }
  .teamMember .card img{
    width: 100%;
    margin: 0 auto;
  }
  .contact-main{
    margin-top: 10em;
    height: 100vh;
    justify-content: center;
  }
  .contact-infomain{
    padding:1em auto;
    margin-top: 2em;
    flex-direction: column;
  }
  .contact-detail h1{
    font-size: .8em;
    font-weight: 400;
    font-family: InterRegular;
  }
  .contact-detail h3{
    font-size: .8em;
    font-weight: 400;
    font-family: InterRegular;
  }
  .contact-row{
    margin: 2em auto;
    
  }
  .contactPageCol{
    width: 88%;
    justify-content: center;
    padding-bottom: 8em;
    }
}

.about-sub {
  display: flex;
  flex-direction: column;
}
.about-sub h1 {
  font-size: large;
}
.founders {
  display: flex;
  flex-direction: row;
  margin: 5em;
  justify-content: center;
}

/* 
.founders img{
  margin: 0;
  width: 30%;
  border-radius: 12;
}

.founders_text{
  width: 50%;
  margin: 12px;
  justify-content: space-around;
  text-align: center;
}
.founders img:hover{
  transform:scale(1.2,1.2)
  
}
.image_container{
  margin: 0;
} */
/* Media Queries */

@media screen and (max-width: 1024px) {
  .navbar {
    width: 80vw;
    margin: 0 auto;
  }

  .logo {
    margin-right: auto;
    margin-left: -15px;
    cursor: pointer;
  }

  .Nav-father {
    display: none;
  }
  .hero h1 {
    color: var(--light);
    text-align: center;
    font-size: 1.5em;
    padding: 0.6em;
    width: 85%;
    font-weight: 600;
    margin: 0 auto;
  }

  .menu {
    display: flex;
  }

  .details {
    width: 80vw;
    margin: 0 auto;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .details-sub {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    font-weight: 300;
    justify-content: space-around;
  }
  .details img {
    max-width: 55%;
    float: right;
    margin: 0 auto;
  }
  .details h1 {
    display: flex;
    flex-direction: column;
    width: 62%;
    color: var(--orange);
    opacity: 0.9;
    font-size: 1em;
    font-family: InterMedium;
  }
  .details-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 1em;
    width: 92%;
    font-family: InterRegular;
    font-weight: 400 !important;
    font-size: 0.9em;
  }
}
@media screen and (max-width: 696px) {
  .founders {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    justify-content: center;
  }
  .teamMembers{
    width: 80vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
  }
  .teamMember {
    flex-direction: column;
    width: 100%;
    margin: 1em auto;
  }
  .teamMember .card img{
    width: 100%;
    margin: 0 auto;
  }
  .founders .card{ 
    display: flex; 
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    max-height: 60vh;
  }
  .founders .card .data{
    position: relative;
    width: 65%;
    height: 80px;
    margin: 0 auto;
    transform: translateY(-150%);
    padding: 10px 2%;
    justify-content: center;
  }
  .founders .card img{
    max-width: 90%;
    margin: 0.5em auto;
  }
  .about-hero h1 {
    font-size: 2em;
    padding: 0 0.6em;
    width: 80%;
  }
  .team-text {
    margin: 1em 0 0 1em;
    width: 80vw;
    font-family: InterRegular;
    font-weight: 400 !important;
    font-size: 1.2em;
    padding: 0 1em;
  }
  .card {
    justify-content: center;
    margin: 0 auto;
    max-height: 50vh;
  }
  .card img {
    width: 58%;
  }
  .data {
    position: relative;
    width: 65%;
    height: 80px;
    margin: 0 auto;
    transform: translateY(-150%);
    padding: 10px 2%;
    justify-content: center;
  }
  .footer-main {
    background-color: #0c0d0e;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100vw;
    font-family: InterRegular;
    align-items: center;
  }
  .footer-sub {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .footer-right {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }
  .quickLinks {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    font-size: small;
    align-items: center;
    margin: 0.5em auto;
  }
  .quickLinks a {
    color: #edfded;
    text-decoration: none;
  }
  .quickLinks a:hover {
    color: var(--orange);
    animation-duration: 3s;
  }
  .footer-logo {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .footlogo {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .footer-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }
  .Karri {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    margin: 0 auto;
  }
  .Karri .quickLinks{
    margin: 2em 1em;
  }
  .footer-social {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }
  .social {
    justify-content: center;
    align-items: center;
  }
  .social svg {
    width: 73%;
  }
  .footer-copy {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }
}
