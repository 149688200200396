:root {
  --dark: #040403;
  --light: #edfded;
  --primary: #5a9cf2;
  --grey: #bfc8ad;
}

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;700&display=swap");

@-webkit-keyframes slideDown {
  0% {
    visibility: hidden;
    height: 0;
  }
  95% {
    visibility: visible;
    height: 25vh;
  }
}

@keyframes slideDown {
  0% {
    visibility: hidden;
    height: 0;
  }
  95% {
    visibility: visible;
    height: 25vh;
  }
}
@keyframes hoveranim {
  0% {
    opacity: 0.3;
  }
  95% {
    opacity: 1;
  }
}
@-webkit-keyframes slideInUp {
  0% {
    opacity: 0;
    transform: translateY(20%);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}

@keyframes slideInUp {
  0% {
    opacity: 0;
    transform: translateY(20%);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}

@-webkit-keyframes backgroundAnim {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}
@keyframes backgroundAnim {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}

@-webkit-keyframes scrollAnim {
  0% {
    -webkit-transform: rotate(-45deg) translate(0, 0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(-45deg) translate(-20px, 20px);
    opacity: 0;
  }
}
@keyframes scrollAnim {
  0% {
    transform: rotate(-45deg) translate(0, 0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: rotate(-45deg) translate(-20px, 20px);
    opacity: 0;
  }
}

body {
  background-color: var(--dark);
  font-family: "Inter", sans-serif;
  color: var(--light);
  overflow-x: hidden;
  margin: 0 auto;
  line-height: 1.45;
}

body::-webkit-scrollbar {
  width: 5px;
  background-color: transparent;
  border-radius: 5px;
}

body::-webkit-scrollbar-thumb {
  background-color: var(--primary);
  border-radius: 5px;
}

.highlight {
  color: var(--primary);
}

.screen {
  height: 100vh;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  justify-content: center;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.column {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.w-p-100 {
  width: 100%;
}

.flex-start {
  justify-content: flex-start;
}

.w-80 {
  width: 80vw;
  margin: 0 auto;
}

.w-30 {
  width: 30vw;
  margin: 0 auto;
}

.w-50 {
  width: 50vw;
  margin: 0 auto;
}

.heading {
  font-size: 2.25em;
  font-weight: 400;
  animation: slideInUp 1s;
  padding: 0.25em;
  margin: 0;
  text-align: center;
}

.heading span {
  color: var(--primary);
}

.subheading {
  padding: 0;
  font-size: 0.85em;
  text-align: center;
  animation: slideInUp 2s;
}

.scroll {
  position: relative;
  cursor: pointer;
}

.scroll span {
  position: absolute;
  top: 0;
  left: 50%;
  width: 24px;
  height: 24px;
  margin-left: -12px;
  border-left: 1px solid #fff;
  border-bottom: 1px solid #fff;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-animation: scrollAnim 1.5s infinite;
  animation: scrollAnim 1.5s infinite;
  box-sizing: border-box;
}

.info h4 {
  font-size: 1.5em;
  color: var(--primary);
  margin: 0 0 1em 0;
  font-weight: 400;
}

.info p {
  padding: 0;
  margin: 0;
  font-size: 0.75em;
  text-align: justify;
}

.image {
  border-radius: 15px;
}

.portfolioContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 27vw;
  height: 30vh;
  cursor: default;
  padding: 1.5em;
  border-radius: 10px;
  margin-top: 1em;
}

.portfolioContent:hover {
  background-color: #5a9cf250;
  cursor: pointer;
}

.portfolioImage {
  height: 5vh;
  width: 5vh;
  margin: 0 1em 0 0;
  border-radius: 100px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  border: 2px solid var(--light);
}

.text-center {
  text-align: center!important;
}

.m-auto {
  margin: auto!important;
}

.m-0 {
  margin: 0!important;
}

.p-0 {
  padding: 0!important;
}

.portfolioContent .profile h3 {
  font-weight: 400;
  font-size: 1.35em;
  padding: 0;
  margin: 0;
}

.portfolioContent .profile p {
  font-size: 0.85em;
}

.portfolioContent .profile-info {
  margin-top: 1em;
  font-size: 0.95em;
}

.portfolioContent a {
  color: #bfc8ad;
  font-size: 1.5em;
  text-decoration: none;
}

@media screen and (max-width: 600px) {
  .row {
    flex-direction: column;
  }
  .w-30 {
    width: 80vw;
    margin: 1em auto;
  }
}
